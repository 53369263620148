import React, { useState, useContext } from "react";
import MainContext from "../context/mainContext";
import "./style.css";
import CustomInput from "../components/customField";
import "../App.css";


export default function Step2({submitButton}) {
  

    const {formData, setFormData,errors, setErrors,handleFormInputChange,validate,handleSubmit} = useContext(MainContext)
   


    return (
        <div className="step2" >
            <p className="label">Please Enter your Details</p>
            <form id="abc" className="details_form container" onSubmit={handleSubmit} >
                <div className="row">
                    <CustomInput
                        class_name={"col-4"}
                        label="First Name*"
                        name="first_name"
                        value={formData.first_name}
                        onChange={handleFormInputChange}
                        placeholder="Enter your name"
                        errorMessage={errors.first_name}
                        required={true}
                    />
                    <CustomInput
                        class_name={"col-4"}
                        label="Last Name*"
                        name="last_name"
                        value={formData.last_name}
                        onChange={handleFormInputChange}
                        placeholder="Enter your last name"
                        errorMessage={errors.last_name}
                        required={true}
                    />
                    <CustomInput
                        class_name={"col-4"}
                        label="Email Address*"
                        name="email"
                        value={formData.email}
                        onChange={handleFormInputChange}
                        placeholder="Enter your email address"
                        errorMessage={errors.email}
                        required={true}
                    />
                    {/* <CustomInput
                        class_name={"col-9"}
                        label="Address"
                        name="address"
                        value={formData.address}
                        onChange={handleFormInputChange}
                        placeholder="Enter your address"
                        errorMessage={errors.address}
                        required={false}
                    /> */}
                    <CustomInput
                        class_name={"col-3"}
                        label="Phone Number*"
                        name="phone"
                        type="number"
                        value={formData.phone}
                        onChange={handleFormInputChange}
                        placeholder="Enter your phone number"
                        errorMessage={errors.phone}
                        required={true}
                    />
                    {/* <CustomInput
                        class_name={"col-3"}
                        label="Zip Code*"
                        name="zip"
                        type="number"
                        value={formData.zip}
                        onChange={handleFormInputChange}
                        placeholder="Enter your Zip Code"
                        errorMessage={errors.zip}
                        required={true}
                    />
                    <CustomInput
                        class_name={"col-3"}
                        label="State*"
                        name="state"
                        value={formData.state}
                        onChange={handleFormInputChange}
                        placeholder="Enter your State"
                        errorMessage={errors.state}
                        required={true}
                    />
                    <CustomInput
                        class_name={"col-3"}
                        label="City"
                        name="city"
                        value={formData.city}
                        onChange={handleFormInputChange}
                        placeholder="Enter your city"
                        errorMessage={errors.city}
                        required={true}
                    /> */}
                </div>
            </form>
            <div className="last_section">
                {/* Uncomment and use if needed */}
                {/* <CustomButton handleSubmit={handleSubmit} /> */}
            </div>
        </div>
    );
}
