import React, { useContext } from "react";
import "./style.css";
import "../App.css"
import MainContext from "../context/mainContext";
import phoneCall from "../../src/assets/phone-call.svg"
import { FaSquarePhone } from "react-icons/fa6";
import { ImMail } from "react-icons/im";



export default function Step3({ }) {
    const { formData, selectedDate, selectedTimeSlot, convertDateString,showSuccess } = useContext(MainContext);
    return (
        <div className="summary_page container">
            <div style={{
                width:`${showSuccess ? "100%":"500px"}`
            }} className="row">
                <div className="selectedDT col-12">
                    <span>Selected Date</span>
                    <span>{convertDateString(selectedDate)}</span>
                </div>
                <div className="selectedDT col-12">
                    <span>Selected Time</span>
                    <span>{selectedTimeSlot}</span>
                </div>

                <div className="otherInfo col-12">
                    <span className="lableHead">Full Name</span>
                    <span className="valueInfo">{formData.first_name} {formData.last_name}</span>
                </div>
                <div className="otherInfo col-8">
                    <span className="lableHead">Email Address</span>
                    <span className="valueInfo">{formData.email}</span>
                </div>
                <div className="otherInfo col-4">
                    <span className="lableHead">Phone</span>
                    <span className="valueInfo">{formData.phone} </span>
                </div>
                {/* <div className="otherInfo col-12">
                <span className="lableHead">Address</span>
                <span className="valueInfo">{formData.address}</span>
                </div>
                <div className="otherInfo col-4">
                    <span className="lableHead">Zip Code</span>
                    <span className="valueInfo">{formData.zip}</span>
                </div>
                <div className="otherInfo col-4">
                <span className="lableHead">City</span>
                <span className="valueInfo">{formData.city}</span>
                </div>
                <div className="otherInfo col-4">
                <span className="lableHead">State</span>
                <span className="valueInfo">{formData.state}</span>
                </div> */}

            </div>
            {showSuccess && <div className="row disclaimer">
                <p>Your Service has been requested. For any query you can Contact Mr Kamal Rehman on below mentioned contact details.</p>
                <p className="info">
                        <FaSquarePhone />
                    <a href="tel:+14845571456">+1 (484) 557-1456</a>
                </p>
                <p className="info">
                        <ImMail />
                    <a href="mailto:Krahman@comcast.net">Krahman@comcast.net</a>
                </p>


            </div>}
        </div>
    )
}