import React, { useState ,useContext} from "react";
import MainContext from "../context/mainContext";
import Calendar from 'react-calendar';
import './style.css';

export default function Calendar0() {
  const {fetchSlots} = useContext(MainContext);
  const [value, setValue] = useState(new Date());

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleDateChange = (date) => {
    setValue(date);
    console.log(formatDate(date))
    fetchSlots(formatDate(date));
  };

  const disableDates = ({ date }) => {
    const today = new Date();
    // const day = date.getDay();
    const isPastDate = date < today.setHours(0, 0, 0, 0);
    // const isThursdayOrFriday = day === 4 || day === 5;

    // return isPastDate || isThursdayOrFriday;
    return isPastDate
  };

  return (
    <div className="mainCalendar">
      <Calendar
        onChange={handleDateChange} 
        value={value}
        tileDisabled={disableDates} 
      />
    </div>
  );
}
