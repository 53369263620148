import React, {useContext} from "react";
import "./style.css";
import Calendar0 from "../calendar/calendar";
import Loader from "../components/loader";
import MainContext from "../context/mainContext";


const slots_dummy = [
  {slot: "06:00 AM - 07:00 AM", availability: "no"},
  {slot: "07:00 AM - 08:00 AM", availability: "no"},
  {slot: "08:00 AM - 09:00 AM", availability: "no"},
  {slot: "09:00 AM - 10:00 AM", availability: "no"},
  {slot: "10:00 AM - 11:00 AM", availability: "no"},
  {slot: "11:00 AM - 12:00 PM", availability: "no"},
  {slot: "12:00 PM - 01:00 PM", availability: "no"},
  {slot: "01:00 PM - 02:00 PM", availability: "no"},
  {slot: "02:00 PM - 03:00 PM", availability: "no"},
  {slot: "03:00 PM - 04:00 PM", availability: "no"},
  {slot: "04:00 PM - 05:00 PM", availability: "no"},
  {slot: "05:00 PM - 06:00 PM", availability: "no"},
  {slot: "06:00 PM - 07:00 PM", availability: "no"},
  {slot: "07:00 PM - 08:00 PM", availability: "no"},
  {slot: "08:00 PM - 09:00 PM", availability: "no"}
]


export default function Step1() {
    const {loading,slots,selectedTimeSlot,setSelectedTimeSlot} = useContext(MainContext)
    return (
        <div className="step1">
            <div className="calendarSection">
                <p className="label">Please Select Date</p>
                <Calendar0/>
            </div>
            <div className="slotsSection">
            <p className="label">Please Select Time Slot</p>
                  {slots ? <div className="slots">
                    {slots.map((item,index)=>(
                         <div onClick={()=>{setSelectedTimeSlot(item.slot)}} className={`slotInd ${item.availability==="no"?"deactivate":""} ${selectedTimeSlot===item.slot?"selected":""}`}>
                         {item.slot}
                     </div>
                    ))}
                 </div>:<div className="slots">
                    {slots_dummy.map((item,index)=>(
                         <div  className={`slotInd ${item.availability==="no"?"deactivate":""}`}>
                         {item.slot}
                     </div>
                    ))}
                 </div>}
                
                
            </div>

        </div>
    )
}